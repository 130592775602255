<template>
    <form v-for="(child, i) in items.options" :key="i" @submit.prevent="testForm">
        <div class="item" v-if="child.type === 'select'" v-click-outside="handleOutside">
            <div class="selected" @click="openSearch = true">
                <span v-if="data[child.name] && !openSearch">{{ data[child.name].name }}</span>
                <input v-if="openSearch || (!openSearch && !data[child.name])" type="text" v-model="parentSearch" :placeholder="`Select ${child.name}`">
            </div>
            <div class="options" v-if="openSearch">
                <template v-for="(item, n) in filteredOptions[child.api]?.filter(el => el.name.toLowerCase().includes(parentSearch.toLowerCase()))" :key="n" >
                    <span @click="setParent(item, child.name, child.relate_to)">
                        {{ item.name }}
                    </span>
                </template>
            </div>
        </div>
        <template v-if="childs.length">
            <template v-for="(children, index) in childs" :key="index">
                <div class="item" v-if="children.type === 'select'">
                    <select v-model="data[children.name]" @change="changeOpt(children.relate_to, children.relate_condition, $event)">
                        <option :value="undefined" disabled selected>Select {{children.name}}</option>
                        <option v-for="(item, n) in filteredOptions[children.api]" :key="n" :value="item">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </template>
        </template>
        <div class="buttons">
            <button v-if="(created.length && !ready && !createNew) || items.name === 'organization'" class="submit" @click="handleSave">Save User</button>
            <span v-if="createNew" class="submit" @click="saveAdd">Save and Add New Client</span>
        </div>
    </form>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
export default {
    props: {
        items: {
            type: Object,
            required: false,
            default: () => {}
        },

        created: {
            type: Array,
            required: false,
            default: () => []
        },
        filledData: {
            type: Object,
            required: false,
            default: () => {}
        },
        toEdit: {
            type: Number,
            required: false,
            default: () => 0
        }
    },

    data() {
        return {
            api: {},
            filteredOptions: {},
            childs: [],
            ready: false,
            createNew: false,
            parentSearch: '',
            openSearch: false,
            data: {},
            user: localStorage['U_P'] ? JSON.parse(localStorage['U_P']) : {},
            client: localStorage.client ? JSON.parse(localStorage.client) : {},
            canCreateOrg: [4, 5, 6],
            userOnClient: false,
            deleted: []
        }
    },

    emits: ['add', 'create'],

    watch: {
        items: {
            immediate: true,
            handler(val) {
                val.options.forEach(element => {
                    this.fetchApi(element.api)
                })
            }
        },

        filledData: {
            deep: true,
            immediate: true,
            handler(val) {
                if(Object.keys(val).length) this.ready = true
                this.data = val
            }
        },
        data: {
            deep: true,
            handler(val) {
                if(val.site) {
                    this.fetchApi('new-site-list')
                }
            }
        },

        toEdit(val) {
            this.filteredOptions['new-org-client-list'].push(this.api['new-org-client-list'].filter(el => el.id === val)[0])
        }
    },

    created() {
      this.emitter.on('reloadClients', (data) => {
            this.deleted = data
            this.items.options.forEach(element => {
                this.fetchApi(element.api)
            })
        })
    },

    methods: {
        async fetchApi(which) {
			let orgData = JSON.parse(localStorage.getItem('organization'));
			const client_id = this.data.client ? this.data.client.id : this.client ? this.client.id : ''
			const response = await axiosInstance.get(`${which}?org_id=${orgData.id}&clientId=${client_id}&limit=100`);
			const responseData = response.data.data

			if (!responseData.last_page) this.api[which] = responseData
			else {
				this.api[which] = []
				this.api[which].push(...responseData.data)
			}

			if(responseData.last_page && responseData.last_page > 1) {
				for (let i = 2; i <= responseData.last_page; i++) {
					const res = await axiosInstance.get(`${which}?org_id=${orgData.id}&clientId=${client_id}&page=${i}&limit=100`);
					this.api[which].push(...res.data.data.data)
				}
			}

            if (which === 'roles-list') {
                switch (this.items.name) {
                    case 'organization':
                            this.filteredOptions[which] = this.api[which].filter(el => el.id !== 9 && el.id !== 10)
                        break;
                    case 'client':
                            this.filteredOptions[which] = this.api[which].filter(el => el.id === 9 || el.id === 10)
                        break;
                }
            }
            else if (which === 'new-org-client-list') {
                if (!this.canCreateOrg.includes(this.user.role.id)) this.api[which] = this.api[which].filter(el => el.id === this.client.id)

                
                const deletedIds =  this.deleted.map(el => el.id)
                const addedClientsIds = this.created.map(el => el.client).filter(element => !deletedIds.includes(element.id)).map(el => el.id)

                this.filteredOptions[which] = this.api[which].filter(el => !addedClientsIds.includes(el.id))

                if (!this.filteredOptions[which].length) {
                    this.userOnClient = true
                    return
                }
                
                this.userOnClient = false
            }
			else this.filteredOptions[which] = this.api[which]
		},

        handleSave() {
            this.$emit('create', this.data)
        },

        changeOpt(to, condition, event) {
            this.ready = false
            this.createNew = false
            if(to && (!condition || event?.target.selectedOptions[0]._value.id === condition)) {
                if (!this.childs.some(el => el.name === to[0].name)) {
                    this.childs.push(...to)
                    to.forEach(element => {
                        this.fetchApi(element.api)
                    })
                }
            }
            else if (to && condition && event.target.value?.id !== condition) {
                if (this.childs.some(el => el.name === to[0].name)) {
                    const index = this.childs.indexOf(el => el.name === to[0].name)
                    this.childs.splice(index, 1)
                    this.data[to[0].name] = undefined
                }
                this.ready = true
                if(this.items.options[0].add_new) this.createNew = true
            }
            else {
                this.ready = true
                if(this.items.options[0].add_new) this.createNew = true
            }
        },

        saveAdd() {
            this.$emit('add', this.data)

            if(this.filteredOptions['new-org-client-list']) this.filteredOptions['new-org-client-list'].splice(this.filteredOptions['new-org-client-list'].map(el => el.id).indexOf(this.data.client.id), 1)

            this.ready = false
            this.createNew = false
            this.data = {}
            this.childs = []
        },

        handleNew() {
            this.$emit('add', this.data)


            if(this.filteredOptions['new-org-client-list']) this.filteredOptions['new-org-client-list'].splice(this.filteredOptions['new-org-client-list'].map(el => el.id).indexOf(this.data.client.id), 1)

            this.ready = false
            this.createNew = false
            this.data = {}
            this.childs = []

            this.$emit('create')
        },

        setParent(value, name, relation) {
            this.data[name] = value
            this.changeOpt(relation)
            this.openSearch = false
            this.parentSearch = ''
        },

        handleOutside() {
            this.openSearch = false
            this.parentSearch = ''
        }
    },
}
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.5rem;

    .item {
        width: 100%;
        display: flex;
        flex-direction: column;

        .selected {
            width: 100%;
            max-width: 25rem;
            display: flex;
            flex-direction: column;

            span {
                padding: 12px;
                background: white;
                cursor: pointer;
                user-select: none;
            }

            input {
                padding: 12px;
                border-bottom: solid 1px rgb(238, 238, 238);
            }
        }

        .options {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 25rem;
            max-height: 150px;
            overflow: auto;

            span {
                padding: 12px;
                background: white;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: rgb(238, 238, 238);
                }
            }
        }
    }

    .buttons {
        width: 100%;
        max-width: 25rem;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
        button, span {
            cursor: pointer;
            width: 100%;
            max-width: 12.25rem;
            padding: 0.5rem 1rem;
            text-align: center;
            transition: 0.2s;
        }

        button {
            background: var(--primary-color);
            color: white;
            &:hover {
                background:  var(--primary-hover);
            }

            &:focus {
                outline: none;
            }
        }

        span {
            background: $grey;
            color: black;
            &:hover {
                background: $grey-hover;
            }
        }
    }
}
</style>