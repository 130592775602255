<template>
    <main>
        <header>
            <div class="goback" @click="goback">
                <img src="/assets/images/left-arrow.svg" alt="close_chevron" />
                <span>go back</span>
            </div>
            <div class="contentTitle">
                <h3>Edit User</h3>
            </div>
        </header>
        <div class="form">
            <template v-for="(section, index) in form" :key="index">
                <section>
                    <div class="title">{{ section.name }}</div>
                    <article>
                        <template v-for="(item, i) in section.items" :key="i">
                            <template v-if="item.type === 'image'">
                                <div class="item import">
                                    <label>
                                        <input ref="importImage" type="file" accept="image/png, image/jpeg"
                                            @change="importImages" />
                                        <figure>
                                            <img :src="image.length
                                                ? image[0].link
                                                : '/assets/images/photo-icon.png'
                                                " alt="" />
                                        </figure>
                                    </label>
                                </div>
                            </template>
                            <template v-else-if="item.type === 'radio'">
                                <div class="item">
                                    <div class="type-of-user" v-if="canEdit.includes(getUserProfile.role?.id)">
                                        <label>User type</label>
                                        <select name="" id="" v-model="selectedRadio">
                                            <option value="Organization">Organization</option>
                                            <option value="Client">Client</option>
                                        </select>
                                    </div>
                                    <span v-else>User type {{ selectedRadio }}</span>

                                    <label :for="item.label">
                                        <span v-if="selectedRadio === 'Client'">{{ item.label }}</span>
                                        <span v-else>Select Role:</span>
                                    </label>
                                    <div v-if="selectedClients.length && selectedRadio === 'Client'" class="clients">
                                        <template v-for="(cli, cli_i) in selectedClients.filter(el => el.role)"
                                            :key="cli_i">
                                            <article class="sel-clients" @click="editClient(cli_i)">
                                                <div class="client">
                                                    <figure>
                                                        <img v-if="cli.client.logo" :src="imgURL + cli.client.logo"
                                                            alt="" />
                                                        <span v-else>{{ cli.client.name.charAt(0) }}</span>
                                                    </figure>
                                                    <span>{{ cli.client.name }}</span>
                                                </div>
                                                <span>-</span>
                                                <div class="role">{{ cli.role?.name }}</div>
                                                <span v-if="cli.sites">-</span>
                                                <div v-if="cli.sites" class="site">
                                                    {{ cli.sites.name }}
                                                </div>
                                                <div class="edit"></div>
                                                <div class="delete"></div>
                                            </article>
                                        </template>
                                    </div>
                                    <template v-for="(child, i) in item.children" :key="i">
                                        <template v-if="child.label === selectedRadio">
                                            <RadioItems :items="child" :created="selectedClients" :filledData="filledData"
                                                :toEdit="edit" :deleted="deletedClients" @add="handleAdd"
                                                @create="create" />
                                        </template>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="item">
                                    <label>{{ item.label }}</label>
                                    <input :type="item.type" v-model="formData[item.name]"
                                        :placeholder="item.label.replace('*', '')" />
                                </div>
                            </template>
                        </template>
                    </article>
                </section>
            </template>
        </div>
    </main>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import form from "./createForm.json";
import RadioItems from "./RadioItems.vue";
import { mapGetters } from "vuex";
import {
    GET_USER_PROFILE_GETTER,
} from '@/store/storeconstants';
export default {
  title() {
    return `Remote Assistance | Create Client`;
  },

  data() {
    return {
      entries: {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      formData: {},
      form,
      selects: {},
      api: {},
      search_key: {},
      multipleShowAll: {},
      filteredOptions: {},
      image: [],
      createUser: false,
      roles: [],
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      selectedRadio: "Organization",
      selectedClients: [],
      selectedOrgRole: {},
      filledData: {},
      edit: 0,
      toFill: {},
      deletedClients: [],
      canEdit: [4,5,6]
    };
  },

  components: {
    RadioItems,
  },

  computed: {
    toCreateUser() {
      return [
        {
          type: "text",
          label: "Name",
        },
        {
          type: "email",
          label: "Email",
        },
      ];
    },
    
    ...mapGetters("account", {
        getUserProfile: GET_USER_PROFILE_GETTER,
    }),

  },

  methods: {
    goback() {
      this.$router.go(-1);
    },

    async fetchUser() {
      const userId = this.$route.params.userid;
      let orgData = JSON.parse(localStorage.getItem("organization"));

      const response = await axiosInstance.post(`get-user-details`, {
        org_id: orgData.id,
        user_id: userId,
      });

      if (response) {
        const res = response.data.data;
        this.formData.avatar = res.avatar || undefined;
        this.formData.name = res.name || undefined;
        this.formData.email = res.email || undefined;
        this.formData.phone_number = res.phone || undefined;

        if (res.user_organizations_role.role) {
          this.selectedRadio = "Organization";
          this.filledData.role = res.user_organizations_role.role || undefined;
        } else {
          this.selectedRadio = "Client";

          res.user_organizations_role.user_client_roles.forEach((el) => {
            this.selectedClients.push({
              rel_cli: el.id,
              client: el.client,
              role: el.role_details,
              site: el.user_sites[0]?.site_details,
            });
          });
        }
      }
    },

    editClient(index) {
      this.edit = this.selectedClients[index].client.id;
      this.deletedClients.push(this.selectedClients[index].rel_cli);
      this.selectedClients.splice(index, 1);
      this.emitter.emit('reloadClients', this.selectedClients)
    },

    async fetchApi(which) {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      const client_id = this.client ? this.client.id : "";
      const response = await axiosInstance.get(
        `${which}?org_id=${orgData.id}&clientId=${client_id}&limit=100`
      );
      const responseData = response.data.data;

      if (!responseData.last_page) this.api[which] = responseData;
      else {
        this.api[which] = [];
        if (which === "get-users")
          this.api[which].push(...responseData.data.map((el) => el.user));
        else this.api[which].push(...responseData.data);
      }

      if (responseData.last_page && responseData.last_page > 1) {
        for (let i = 2; i <= responseData.last_page; i++) {
          const res = await axiosInstance.get(
            `${which}?org_id=${orgData.id}&clientId=${client_id}&page=${i}&limit=100`
          );
          this.api[which].push(...res.data.data.data);
        }
      }

      this.filteredOptions[which] = this.api[which];
    },

    importImages(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          this.image = [
            { name: file.name, link: fr.result, originalFile: file },
          ];

          this.$refs.importImage.value = "";
        };
      }
    },

    async create(data) {
        const orgData = JSON.parse(localStorage.getItem("organization"));

        const toSend = {
            org_id: orgData.id,
            id: this.$route.params.userid
        }

        if (this.formData.name) toSend.name = this.formData.name
        if (this.formData.email) toSend.email = this.formData.email
        if (this.formData.phone_number) toSend.phone = this.formData.phone_number

        if (this.selectedClients.length) {
            toSend.role_type = 'client'
            toSend.roles = this.selectedClients.map(el => {
                const toReturn = {
                    client: el.client.id,
                    role: el.role.id,
                }
                if (el.site) toReturn.site = el.site.id

                return toReturn
            })
        }

        if (data.role) {
            toSend.role_type = 'organisation'
            toSend.roles = data.role.id
        }
      
      const response = await axiosInstance.patch(
        `edit-user`,
        toSend
      );
      if (response) this.$router.push({ path: "/data-structure/users" });
      this.emitter.emit("alert", response.data.message);
    },

    selRadio(val) {
      this.selectedRadio = val.target.value;
    },

    handleAdd(val) {
      if (Object.keys(val).length > 1) this.selectedClients.push(val);
      else this.selectedOrgRole = val.role;
    },
  },

  mounted() {
    this.form.forEach((element) => {
      const apis = element.items.filter((el) => el.api).map((el) => el.api);
      if (apis.length) {
        apis.forEach((el) => {
          this.fetchApi(el);
          this.search_key[el] = "";
          this.multipleShowAll[el] = false;
        });
      }
    });

    this.fetchUser();
  },
};
</script>

<style lang="scss" scoped>
main {
    display: flex;
    flex-direction: column;
    gap: 20px;

    header {
        .goback {
            display: flex;
            align-items: center;
            gap: 5px;
            user-select: none;
            cursor: pointer;
            transition: 0.2s;

            img {
                opacity: 0.5;
                width: 10px;
            }

            span {
                opacity: 0.5;
                transition: 0.2s;

                &:hover {
                    opacity: 1;
                    color: var(--primary-color);
                }
            }
        }

        .info {
            font-size: 0.7rem;
        }
    }

    .form {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(3, 1fr);
        padding-block: 2rem;
        width: 100%;
        margin-inline: auto;

        section {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;

            .title {
                font-size: 1.2rem;
                font-weight: 500;
                text-transform: capitalize;
            }

            article {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .item {
                    display: flex;
                    flex-direction: column;

                    &.import {
                        figure {
                            background: #f2f5f8;
                            transition: 0.2s;
                            width: 100px;
                            height: 100px;
                            overflow: hidden;
                            border: 1px solid #0515241f;
                            border-radius: 5px;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            &:hover {
                                background: #0515241f;
                            }
                        }

                        .importedImg {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        input {
                            display: none;
                        }
                    }

                    label {
                        font-weight: 500;
                        width: fit-content;
                        display: flex;
                        gap: 10px;

                        .new {
                            background: var(--primary-color);
                            color: white;
                            font-size: 0.7rem;
                            padding: 2px 10px;
                            user-select: none;
                            cursor: pointer;
                            border-radius: 2px;
                            transition: 0.2s;

                            &:hover {
                                background: #1d4ec0;
                            }
                        }
                    }

                    input {
                        border: 1px solid #cad6e2;
                        border-radius: 2px;
                        padding: 10px;
                        max-width: 25rem;

                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }
            }
        }
    }
}

.type-of-user {
    display: flex;
    flex-direction: column;

    select {
        margin: 1rem 0 1.5rem;
        width: 100%;
        max-width: 25rem;
        box-shadow: none;
        padding: 0.5rem 1rem;
        border: 1px solid #CAD6E2;
        border-radius: 2px;
    }
}

.options {
    display: none;
    position: absolute;
    left: 0;
    height: 120px;
    max-height: 150px;
    overflow: auto;
    border-radius: 5px;
    width: 100%;
    background: white;
}

.option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    display: none;
    min-width: 150px;
}

.option img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.option,
.label {
    font-size: 16px;
}

input[id*="options-view-button"]:checked~.options {
    display: block;
}

input[id*="options-view-button"]:checked~.options .option {
    display: flex;
    background: white;
}

input[id*="options-view-button"]:checked~.options .option:hover {
    background: #f2f5f8;
    border-radius: 5px;
}

#select-button {
    border-radius: 5px;
    border: solid 1px #cad6e2;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#select-button .arrow {
    width: 10px;
}

#select-button .arrow img {
    opacity: 0.5;
    width: 100%;
    transform: rotate(270deg);
    transition: 0.2s;
}

input[id*="options-view-button"]:checked~#select-button .arrow img {
    transform: rotate(90deg);
}

#select-box {
    position: relative;
    background: white;
    width: 25rem;
}

input[id*="options-view-button"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

#selected-value {
    font-size: 16px;
    line-height: 1;
    margin-right: 26px;
    text-transform: capitalize;
}

input[id*="options-view-button"]:checked~.options {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    z-index: 4;
}

.label {
    display: none;
    padding: 0;
}

input[id*="options-view-button"]:checked~.options .label {
    display: block;
}

.s-c {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
}

.s-c.top {
    top: 0;
}

.s-c.bottom {
    bottom: 0;
}

.s-c:hover~i {
    color: #fff;
    opacity: 0;
}

.s-c:hover {
    height: 100%;
    z-index: 1;
}

.label {
    transition: 0.3s ease all;
}

.opt-val {
    position: absolute;
    left: 14px;
    width: 217px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
}

.option input[type="radio"]:checked~.opt-val {
    opacity: 1;
    transform: scale(1);
}

.option input[type="radio"]:checked~img {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
}

.option input[type="radio"]:checked~img,
.option input[type="radio"]:checked~.label {
    color: #fff;
}

.option input[type="radio"]:checked~.label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

input[id*="options-view-button"]:not(:checked)~.options .option input[type="radio"]:checked~.opt-val {
    top: -30px;
}

input[id*="options-view-button"]:not(:checked)~.options {
    z-index: 1;
}

.option input[type="radio"]:checked~.label:before {
    background-color: #000;
    border-radius: 4px;
}

#option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: none;
}

input[id*="options-view-button"]:checked~.options #option-bg {
    display: block;
}

.optionhover~#option-bg {
    top: 0;
    background-color: rgba(97, 97, 97, 0.692);
    border-radius: 4px 4px 0 0;
}

#ytd-url {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 10px 14px;
    margin: 20px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    background-color: #143240;
    border-radius: 4px;
    box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}

.sel {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sel .sel-img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

.submit {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.submit span {
    padding: 10px 25px;
    border-radius: 30px;
    background: var(--primary-color);
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
}

.submit span:hover {
    background: #1d4ec0;
}

.item {
    .multiple-container {
        border: solid 1px #cad6e2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        height: 10rem;
        overflow: auto;
        width: 25rem;
        border-radius: 2px;
        background: white;

        input {
            border: solid 1px #cad6e2;
            height: 30px;
        }

        .all-items {
            height: 150px;
            overflow: auto;
            border: solid 1px #cad6e2;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            display: flex;
            flex-direction: column;

            .multiple-search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                border-bottom: #cad6e2;
                transition: 0.2s;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background: #cad6e2;
                }

                .item-info {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .no-img {
                        width: 25px;
                        height: 25px;
                        background: var(--primary-color);
                        color: white;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }

                    .text {
                        width: calc(100% - 40px);
                        padding: 0 10px 0 0;
                        font-size: 0.7rem;
                    }
                }

                .add-item {
                    background: var(--primary-color);
                    color: white;
                    padding: 5px 20px;
                    border-radius: 5px;

                    &:hover {
                        background: #1d4ec0;
                        color: white;
                        padding: 5px 20px;
                        border-radius: 5px;
                    }
                }
            }
        }

        .selected-items {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .added-item {
                width: calc(50% - 5px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    background: #cad6e2;
                }

                .item-info {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .no-img {
                        width: 25px;
                        height: 25px;
                        background: var(--primary-color);
                        color: white;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                    }
                }

                .remove-item {
                    color: rgb(163, 10, 10);
                    transition: 0.2s;

                    &:hover {
                        color: rgb(97, 10, 10);
                    }
                }
            }
        }
    }

    .radio-container {
        display: flex;
        gap: 2.5rem;

        .radio {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            label {
                margin: 0;
            }
        }
    }

    .clients {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1.5rem;
        background: white;
        width: 100%;
        max-width: 25rem;
        padding: 0.5rem 1rem;

        .sel-clients {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            user-select: none;

            .client {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                width: 50%;

                figure {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: white;
                        background: var(--primary-color);

                    }
                }

                span {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}

.submit-form {
    span {
        background: var(--primary-color);
        color: white;
        padding: 10px;
        user-select: none;
        cursor: pointer;
        border-radius: 2px;
        transition: 0.2s;
        text-transform: capitalize;

        &:hover {
            background: #1d4ec0;
        }
    }
}

@media only screen and (max-width: 1200px) {
    main {
        .form {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 600px) {
    main {
        .form {
            grid-template-columns: none;
            grid-template-rows: repeat(3, 1fr);
        }
    }
}
</style>