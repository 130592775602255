<template>
  <section class="sites-list">
    <div class="table-head">
      <div class="main-items">
        <span class="ticket">Name</span>
        <span class="ticket">Serial Number</span>
        <span class="ticket">Product Number</span>
        <span class="ticket">Site</span>
      </div>
    </div>
    <template v-if="products.length">
      <div class="items">
        <div class="item" :title="`Click to go to ${entry.product.name}`" v-for="(entry, index) in products" :key="index" @click="$router.push(`/products/equipments/${entry.product.id}`)">
          <span class="user-info">
            <div class="user-desc">
              <div class="name">{{ entry.product.name }}</div>
            </div>
          </span>
          <span>{{ entry.product.serial_no }}</span>
          <span>{{ entry.product.product_number }}</span>
          <span>{{ entry.site }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <span class="empty"> There are currently no Products associated with this client. </span>
    </template>
  </section>
</template>

<script>
export default {
  props: {
    sites: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      products: []
    };
  },

  created() {
    this.setProducts()
  },

  methods: {
    setProducts() {
        this.sites.forEach(site => {
            if (site.site_details?.product_list?.length) {
                site.site_details.product_list.forEach(product => {
                    if (product.products_details) this.products.push({product: product.products_details, site: site.site_details.name})
                })
            }
        })
    },
  }


};
</script>

<style lang="scss" scoped>
.sites-list {
  .table-head {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    box-shadow: 0px 1px 6px #cad6e280;
    border-bottom: solid 1px #f2f5f8;
    background: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .main-items {
      width: 100%;
      display: flex;

      span {
        width: calc(100% / 3);
        display: flex;
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }

  .items {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: white;
    .item {
      width: 100%;
      padding: 10px 25px;
      display: flex;
      border-bottom: solid 1px #f2f5f8;
      .user-info {
        display: flex;
        align-items: center;
        gap: 10px;
        figure {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }

        .user-desc {
          .name:first-letter {
            text-transform: uppercase;
          }
        }
      }
      span {
        width: calc(100% / 3);
        cursor: pointer;
        font-size: 1rem;
      }
    }
  }
}
</style>