<template>
  <main>
    <header>
      <div class="goback" @click="goback">
        <img src="/assets/images/left-arrow.svg" alt="close_chevron" />
        <span>go back</span>
      </div>
      <div class="contentTitle">
        <h3>Create Client</h3>
      </div>
      <div class="info">
        *Only the Client Name is required, everything else can be finished later
        by an admin or a leader user.
      </div>
    </header>
    <div class="form">
      <template v-for="(section, index) in toCreate" :key="index">
        <section>
          <div class="title">{{ section.name }}</div>
          <article>
            <template v-for="(item, i) in section.items" :key="i">
              <template v-if="item.type === 'image'">
                <div class="item import">
                  <label>
                    <input
                      ref="importImage"
                      type="file"
                      accept="image/png, image/jpeg"
                      @change="importImages"
                    />
                    <figure>
                      <img
                        :src="
                          image.length
                            ? image[0].link
                            : '/assets/images/photo-icon.png'
                        "
                        alt=""
                      />
                    </figure>
                  </label>
                </div>
              </template>
              <Select v-else-if="item.type === 'select'" :item="item" @data="handleData"/>
              <template v-else-if="item.type === 'multiple-select'">
                <span class="new" v-if="item['create-new']" @click="createUser = true">Create User</span>
                <MultipleSelect :item="item" @data="handleData"/>
                <section class="users-created" v-if="item['create-new'] && usersCreated.length">
                    <span class="subtitle">Users Created for Client</span>
                    <article>
                        <template v-for="(user,i) in usersCreated" :key="i">
                            <div class="usr">
                                <span>{{user.name}}</span>
                                <span class="rem" @click="removeCreatedUser(i)">x</span>
                            </div>
                        </template>
                    </article>
                </section>
              </template>
              <template v-else>
                <div class="item">
                  <label>{{ item.label }}</label>
                  <input
                    :type="item.type"
                    v-model="formData[item.label]"
                    :placeholder="item.name"
                  />
                </div>
              </template>
            </template>
          </article>
        </section>
      </template>
    </div>
    <div class="submit-form">
      <span @click="create">create client</span>
    </div>
  </main>
  <section class="load" v-if="!loaded">
    <div class="loading">
        <span class="loader"></span>
    </div>
  </section>
  <CreateModal
    v-if="createUser"
    :items="toCreateUser"
    :title="'Create User'"
    @submitData="createNewUser"
    @close="createUser = false"
  />
</template>

<script>
import CreateModal from "@/components/Ui/CRUD/Create.vue";
import axiosInstance from "@/services/AxiosTokenInstance";
import form from "./createForm.json";
import Select from '@/components/Create/Templates/Select.vue'
import MultipleSelect from '@/components/Create/Templates/MultipleSelect.vue'
import api from "@/mixins/optionsApi";
export default {
  title() {
    return `Remote Assistance | Create Client`;
  },

  data() {
    return {
      entries: {},
      imgURL: process.env.VUE_APP_DO_SPACES,
      formData: {},
      form,
      selects: {},
    //   api: {},
      search_key: {},
      multipleShowAll: {},
      filteredOptions: {},
      image: [],
      createUser: false,
      roles: [],
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      loaded: true,
      usersCreated: []
    };
  },

  components: {
    CreateModal,
    Select,
    MultipleSelect
  },

  mixins: [api],

  computed: {
    toCreateUser() {
      return [
        {
          type: "text",
          label: "Name",
        },
        {
          type: "email",
          label: "Email",
        },
      ];
    },
    

    toCreate() {
        return [
            {
                name: "client info",
                items: [
                    {
                        name: "Logo",
                        label: "Client Logo",
                        type: "image"
                    },
                    {
                        name: "Name",
                        label: "Client Name",
                        type: "text"
                    },
                    {
                        name: "Country",
                        label: "Country",
                        type: "select",
                        options: this.api("countries"),
                    },
                    {
                        name: "Post Code",
                        label: "Post Code",
                        type: "text"
                    },
                    {
                        name: "City",
                        label: "City",
                        type: "text"
                    },
                    {
                        name: "Address",
                        label: "Address",
                        type: "text"
                    }
                ]
            },
            {
                name: "relations",
                items: [
                    {
                        name: "Leader Users",
                        label: "Leader Users",
                        type: "multiple-select",
                        options: this.api("client_users"),
                        "create-new": true
                    }
                ]
            },
            {
                name: "contacts",
                items: [
                    {
                        name: "Name",
                        label: "Contact Person Name",
                        type: "text"
                    },
                    {
                        name: "Number",
                        label: "Contact Person Number",
                        type: "number"
                    },
                    {
                        name: "Email",
                        label: "Contact person Email",
                        type: "email"
                    }
                ]
            }
        ]
    },
  },

  methods: {
    goback() {
      this.$router.push({ path: "/data-structure/clients" });
    },

    handleOutside() {
      const refs = Object.keys(this.$refs).filter((el) => el.includes("cb-"));
      refs.forEach((el) => {
        this.$refs[el].checked = false;
      });
    },

    importImages(event) {
      const file = event.target.files[0];

      if (FileReader && file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = () => {
          this.image = [
            { name: file.name, link: fr.result, originalFile: file },
          ];

          this.$refs.importImage.value = "";
        };
      }
    },

    handleData(data) {
        this.selects[data[0]] = data[1]
    },

    async create() {
      this.loaded = false
      let orgData = JSON.parse(localStorage.getItem("organization"));
      Object.keys(this.formData).forEach((element) => {
        this.selects[element] = this.formData[element];
      });
      this.selects.Images = this.image;

      let toSend = new FormData();
      toSend.append("org_id", orgData.id);
      toSend.append("name", this.selects["Client Name"] || "");
      toSend.append(
        "logo",
        this.selects.Images.length ? this.selects.Images[0].originalFile : ""
      );
      toSend.append("address", this.selects.Address || "");
      toSend.append("post_code", this.selects["Post Code"] || "");
      toSend.append("city", this.selects.City || "");
      toSend.append(
        "country_id",
        this.selects.Country ? this.selects.Country.id : 0
      );
      toSend.append(
        "contact_person_phone",
        this.selects["Contact Person Number"] || ""
      );
      toSend.append(
        "contact_person_name",
        this.selects["Contact Person Name"] || ""
      );
      toSend.append(
        "contact_person_email",
        this.selects["Contact person Email"] || ""
      );

      const concatUsers = this.selects["Leader Users"] ? this.selects["Leader Users"].concat(this.usersCreated) : this.usersCreated

      if (concatUsers.length) {
        for (let i = 0; i < concatUsers.length; i++) {
          toSend.append(`user_ids[${i}]`, concatUsers[i].id);
        }
      }

      const response = await axiosInstance.post(
        `complete-client-registration`,
        toSend
      ).finally(() => this.loaded = true);
      if (response) this.$router.push({ path: "/data-structure/clients" });
    },

    removeCreatedUser(index) {
        this.usersCreated.splice(index, 1)
    },

    async createNewUser(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      const toSend = {
        org_id: org.id,
        name: data.Name,
        email: data.Email,
        role_id: 9,
        creation_type: "from_client",
      };

      const response = await axiosInstance.post(
        `complete-user-registration`,
        toSend
      );
      if (response) {
        this.emitter.emit("alert", response.data.message);
        const user = {
          id: response.data.data.user_id,
          name: data.Name,
          image: null,
        };

        this.usersCreated.push(user)
      } else this.emitter.emit("alert", "Something went wrong");
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  header {
    .goback {
      display: flex;
      align-items: center;
      gap: 5px;
      user-select: none;
      cursor: pointer;
      transition: 0.2s;
      img {
        opacity: 0.5;
        width: 10px;
      }
      span {
        opacity: 0.5;
        transition: 0.2s;
        &:hover {
          opacity: 1;
          color: var(--primary-color);
        }
      }
    }
    .info {
      font-size: 0.7rem;
    }
  }

  .form {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 2rem;
    width: 100%;
    margin-inline: auto;

    section {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      .title {
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: capitalize;
      }
      article {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        position: relative;


        .new {
            width: 100px;
            position: absolute;
            left: calc(25rem - 100px);
            top: -20px;
            background: var(--primary-color);
            color: white;
            font-size: 0.7rem;
            padding: 2px 10px;
            user-select: none;
            cursor: pointer;
            border-radius: 2px;
            transition: 0.2s;
            text-align: center;
            padding: 10px;
            &:hover {
            background: #1d4ec0;
            }
        }

        .item {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 25rem;

          &.import {
            figure {
              background: #f2f5f8;
              transition: 0.2s;
              width: 100px;
              height: 100px;
              overflow: hidden;
              border: 1px solid #0515241f;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              &:hover {
                background: #0515241f;
              }
            }

            .importedImg {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            input {
              display: none;
            }
          }

          label {
            font-weight: 500;
            width: fit-content;
            display: flex;
            gap: 10px;
            color: var(--primary-color);
            font-size: 1rem;
          }

          input {
            border: 1px solid #cad6e2;
            border-radius: 2px;
            padding: 10px;
            max-width: 25rem;

            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
}

.users-created {
    width: 100%;
    max-width: 25rem;
    .subtitle {
        padding: 5px 0px;
        font-weight: 500;
        color: #cad6e2;
    }

    article {
        
        display: flex;
        flex-direction: column;
        div {
            position: relative;
            display: flex;
            align-items: center;
            span {
                display: flex;
                flex-wrap: wrap;
                border-left: solid 1px var(--primary-color);
                padding: 10px 15px;
                border-bottom: 1px solid #eeeeee;
                border-right: 1px solid #eeeeee;

                &:first-of-type {
                    border-top: 1px solid #eeeeee;
                }
            }

            .rem {
                border: none;
                position: absolute;
                right: 15px;
                padding: 0;
                cursor: pointer;
                user-select: none;
                transition: 0.2s;

                &:hover {
                    color: $red;
                }
            }
        }
    }
}

.submit-form {
  span {
    background: var(--primary-color);
    color: white;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 2px;
    transition: 0.2s;
    text-transform: capitalize;
    &:hover {
      background: #1d4ec0;
    }
  }
}

.load {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.2);
  width: 100%;
  height: 100%;

  .loading {
    background: unset;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  main {
    .form {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
}
@media only screen and (max-width: 600px) {
  main {
    .form {
      grid-template-columns: none;
      grid-template-rows: repeat(3, 1fr);
    }
  }
}
</style>