<template>
  <div class="client-info">
    <div class="image">
      <img
        :src="
          info.logo
            ? imgURL + info.logo
            : '/assets/images/model-inspection2.png'
        "
        alt=""
      />
    </div>
    <div class="info">
      <div class="title">{{ info.name }}</div>
      <div class="details">
        <div class="item">
          <div class="sub">Country</div>
          <div class="desc">
            {{ info.country ? info.country.name : "undefined" }}
          </div>
        </div>
        <div class="item">
          <div class="sub">City</div>
          <div class="desc">{{ info.city || "undefined" }}</div>
        </div>
        <div class="item">
          <div class="sub">Address</div>
          <div class="desc">{{ info.address || "undefined" }}</div>
        </div>
        <div class="item">
          <div class="sub">Email</div>
          <div class="desc">{{ info.contact_person_email || "undefined" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },
};
</script>

<style lang="scss" scoped>
.client-info {
  width: 100%;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 1px 6px #cad6e280;
  padding: 25px;
  display: flex;
  gap: 25px;
  .image {
    width: 16%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .info {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .title {
      font-weight: bold;
      font-size: 1.5rem;
    }
    .details {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      .desc {
        font-weight: 500;
      }
    }
  }

  .qr-code {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
</style>