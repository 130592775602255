<template>
  <template v-if="currentClient">
    <div class="goback" @click="goback">
      <img src="/assets/images/left-arrow.svg" alt="close_chevron" />
      <span>go back</span>
    </div>
    <div class="contentTitle">
      <h3>{{ currentClient.name || "Client" }}</h3>
    </div>
    <div class="contentMenu">
      <ul class="navbar-nav">
        <li
          class="nav-item"
          :class="{ active: isActive === 'info' }"
          @click="isActive = 'info'"
        >
          <a class="nav-link" href="#">Info</a>
        </li>
        <li
          class="nav-item"
          :class="{ active: isActive === 'users' }"
          @click="isActive = 'users'"
        >
          <a class="nav-link" href="#">Users</a>
        </li>
        <li
          class="nav-item"
          :class="{ active: isActive === 'sites' }"
          @click="isActive = 'sites'"
        >
          <a class="nav-link" href="#">Sites</a>
        </li>
        <li
          class="nav-item"
          :class="{ active: isActive === 'products' }"
          @click="isActive = 'products'"
        >
          <a class="nav-link" href="#">Products</a>
        </li>
      </ul>
    </div>
    <section class="users-list">
      <template v-if="Object.keys(currentClient).length">
        <info v-if="isActive === 'info'" :info="currentClient" />
        <users v-if="isActive === 'users'" :data="currentClient" />
        <sites v-if="isActive === 'sites'" :sites="currentClient.sites" />
        <products v-if="isActive === 'products'" :sites="currentClient.sites" />
      </template>
    </section>
  </template>
  <template v-else>
    <div class="loading">
      <span class="loader"></span>
    </div>
  </template>
</template>

<script>
import Info from "./Info.vue";
import Users from "./Users.vue";
import Sites from "./Sites.vue";
import Products from "./Products.vue";
export default {
  props: {
    client: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      currentClient: this.client ? JSON.parse(this.client) : undefined,
      isActive: "info",
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  components: {
    Info,
    Users,
    Sites,
    Products
  },

  methods: {
    goback() {
      this.$router.push({ path: "/data-structure/clients" });
    },
  },

  mounted() {
    if (!this.currentClient)
      this.$router.push({ path: "/data-structure/clients" });
  },
};
</script>

<style lang="scss" scoped>
.goback {
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
  cursor: pointer;
  transition: 0.2s;
  img {
    opacity: 0.5;
    width: 10px;
  }
  span {
    opacity: 0.5;
    transition: 0.2s;
    &:hover {
      opacity: 1;
      color: var(--primary-color);
    }
  }
}
</style>